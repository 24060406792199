import { isAuthGuardActive } from '@/constants/config';
import { getCurrentUser } from '.';

export default (to, from, next) => {
    const user = getCurrentUser();

    if (to.matched.some(record => record.meta.loginRequired)) {

        if (isAuthGuardActive) {
            if (user) {
                const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);

                if (roleArrayHierarchic.every(x => x.includes(user.level_aplikasi_elemen))) {
                    if (to.matched.some(record => record.meta.institutionAdminRequired)) {
                        if (user.institution_mapper == true || user.jabatan_flag === 'express_user' ) {
                            next();
                        }
                        else {
                            next('/dashboard')
                        }
                    }
                    if (to.matched.some(record => record.meta.adminRequired)) {
                        if (user.upline_mapper == true || user.elemen_mapper == true) {
                            next();
                        }
                        else {
                            next('/dashboard')
                        }
                    }
                    if (to.matched.some(record => record.meta.superAdminRequired)) {
                        if (user.elemen_mapper == true) {
                            next();
                        }
                        else {
                            next('/dashboard')
                        }
                    }
                    next();
                }
                else {
                    next('/memo/inbox')
                }
            } else {
                // setCurrentUser(null);
                next('/auth/welcome')
                return
            }
        } else {
            next();
        }
    }
    else {
        if (user) {
            //user maksa ubah url ke laman login padahal sudah login
            next('/memo/inbox');
            return
        }
        else {
            next()
        }
    }
}
